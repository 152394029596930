import "./assets/scss/home.scss";
import BTExpand from "./assets/resources/home/bt-expand/bt-expand.png";
import BTClose from "./assets/resources/home/bt-close/bt-close.png";

document.addEventListener("DOMContentLoaded", function(event) {
  document.querySelectorAll('.qna__question').forEach((item) => {
    (function () {
      let target = item as HTMLElement;
      let targetImg = target.querySelector('.qna__question__action') as HTMLImageElement;
      target.addEventListener('click', () => {
        let content = target.parentElement.querySelector('.qna__question__answer') as HTMLElement;
        if(content.style.display == 'none') {
          targetImg.src = BTClose;
          content.style.display = '';
        } else {
          targetImg.src = BTExpand;
          content.style.display = 'none';
        }
      });
    })()
  });
});